import { CREATE_USER_API } from '../config';

/**
 * Extracts the error message from the response data.
 * @param {Object} data - The error data from the response.
 * @returns {string} - The extracted error message.
 */
const extractErrorMessage = data => {
    if (data?.errorCauses?.length > 0 && data.errorCauses[0].errorSummary) {
        return data.errorCauses[0].errorSummary;
    }
    if (data?.errorSummary) return data.errorSummary;
    return 'An error has occurred. The user could not be created. Please try again.';
};

/**
 * Helper function to generate a structured error response.
 * @param {number} status - HTTP status code.
 * @param {Object} data - Response data.
 * @returns {Object} - Structured error response.
 */
const getErrorResponse = (status, data) => ({
    responseNumber: status,
    success: false,
    errorCode: data.errorCode || 'UnknownError',
    message: extractErrorMessage(data)
});

/**
 * Creates a new user in Okta via the Azure Function with the given details.
 * Validates the required properties, sends a POST request, and handles errors.
 *
 * @param {Object} userDetails - Details of the user to be created.
 * @param {string} userDetails.firstName - The first name of the user.
 * @param {string} userDetails.lastName - The last name of the user.
 * @param {string} userDetails.email - The email address of the user.
 * @returns {Promise<Object>} - A response object with status, success flag, and data or error details.
 */
const createUser = async userDetails => {
    const { firstName, lastName, email } = userDetails || {};

    // Validate required user details
    if (!firstName || !lastName || !email) {
        console.error('Missing required user details:', userDetails);
        return {
            responseNumber: 400,
            success: false,
            errorCode: 'InvalidInput',
            message: 'User details not complete. First name, last name, and email are required.'
        };
    }

    const url = CREATE_USER_API; // URL of the Azure Function

    const headers = {
        'Content-Type': 'application/json',
        'Accept-Language': 'en-US'
    };

    // Only send the necessary fields for the function's validation
    const body = { firstName, lastName, email };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(body)
        });

        // Check if response has JSON content by inspecting Content-Type header
        const contentType = response.headers.get('Content-Type') || '';
        const data = contentType.includes('application/json') ? await response.json() : {};

        // If the response is not successful, return a structured error response.
        if (!response.ok) {
            console.error('Error creating user:', data);
            return getErrorResponse(response.status, data);
        }

        return {
            responseNumber: response.status,
            success: true,
            data
        };
    } catch (error) {
        console.error('Error creating user:', error);
        return {
            responseNumber: 0,
            success: false,
            errorCode: 'NetworkError',
            message: 'An unexpected network error occurred.'
        };
    }
};

export default createUser;
