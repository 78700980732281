// Import the API URL from the configuration file so that it can be centrally maintained.
import { OKTA_WORKFLOW_REQUEST_URL } from '../config';

/**
 * Sends a POST request to create a workflow with a specified oktaID and groupID.
 *
 * The function validates the input parameters, sends a POST request with JSON payload,
 * evaluates the response, and handles any errors that occur during the process.
 *
 * @param {string} oktaID - The unique identifier for the Okta user.
 * @param {string} groupID - The identifier for the group the user should belong to.
 * @returns {Promise<Object>} - Resolves to an object containing the HTTP status and parsed JSON data.
 * @throws {Error} - Throws an error if required parameters are missing or if the HTTP request fails.
 */
const createRequest = async (oktaID, groupID) => {
    // Validate that both oktaID and groupID are provided.
    if (!oktaID || !groupID) {
        // If either parameter is missing, create an error with a specific message and properties.
        const error = new Error('Object not complete. Both oktaID and groupID are required.');
        error.status = 500;  // Here, 500 is used to indicate a server error, adjust if needed.
        error.errorCode = 'InvalidInput';  // Custom error code to indicate the type of error.
        console.error('Error invoking workflow:', error);
        throw error; // Terminate the function execution by throwing the error.
    }

    try {
        // Send a POST request to the specified URL with the given parameters in the request body.
        const response = await fetch(OKTA_WORKFLOW_REQUEST_URL, {
            method: 'POST',  // Use POST HTTP method.
            headers: { 'Content-Type': 'application/json' },  // Inform the server that the request body is JSON.
            body: JSON.stringify({ oktaID, groupID }), // Convert the JavaScript object to a JSON string.
        });

        // If the response does not indicate a successful HTTP status (i.e., not in the 200-299 range),
        // create an error with the response status and log it.
        if (!response.ok) {
            const error = new Error(`HTTP error! status: ${response.status}`);
            error.status = response.status;
            console.error('Error invoking workflow:', error);
            throw error; // Throw the error to be caught in the catch block below.
        }

        // Parse the JSON response body to obtain the response data.
        const data = await response.json();
        // Return the HTTP status along with the parsed data encapsulated in an object.
        return { status: response.status, data };
    } catch (error) {
        // If any error occurs (either during the fetch or JSON parsing), log the error.
        console.error('Error invoking workflow:', error);
        // Ensure that the error always has a 'status' property, defaulting to 500 if undefined.
        error.status = error.status || 500;
        // Rethrow the error so that it can be handled by the calling code.
        throw error;
    }
};

export default createRequest;
