/* This file is a template file for the URLs. It is used to generate a config.js file with the help 
of the workflow file during deployment. The links are stored as secrets in GitHub. */

export const OKTA_URL = 'https://red-bull.oktapreview.com/';
export const OKTA_WORKFLOW_REQUEST_URL = 'https://func-b2b-spark-qa002.azurewebsites.net/api/func-httptrigger-create-request?code=XYvcuWPmnGqL8ETH8gfM_Cga7G6W3dJbSVR5q3gWXimjAzFuj2P7Fg==';
export const OKTA_SUCCESS_URL = 'https://red-bull.oktapreview.com/';
export const OKTA_HELP_URL = 'https://red-bull.oktapreview.com/help/login';
export const AZURE_MAPS_IP_LOCALISATION = 'https://func-b2b-spark-iplocalisation.azurewebsites.net/api/userLocalisationOverIp?code=dTq9MXY9xLvYSP7T8jCyJiR1WjnKlSNAGV6Q_6f9NB7vAzFunFgNnw=='
export const GET_GROUP_API = "https://func-b2b-spark-qa002.azurewebsites.net/api/selfReg-getGroupsByCountry?code=RiC_V31mIxKo55KiebyJNJBdqgyGOiZpRxPvYf7hXELaAzFuKDoiVA==";
export const CREATE_USER_API = "https://func-b2b-spark-qa002.azurewebsites.net/api/selfReg-createUser?code=zATHfvJQv-O7ioZsWbl3nZ6ukECn_b3ldYNxeD-7tK8eAzFu7RxdIw==";
