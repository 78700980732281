import { AZURE_MAPS_IP_LOCALISATION } from '../config';

/**
 * Retrieves the user's location.
 * Returns the location data only if the country ISO code equals 'US',
 * otherwise returns null.
 *
 * @returns {Promise<Object|null>} The location data if the user is in the US, otherwise null.
 */
const readUserLocation = async () => {
    try {
        // Fetch location data from the backend service
        const response = await fetch(AZURE_MAPS_IP_LOCALISATION, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            console.error('Error fetching location data:', response.status, response.statusText);
            return null;
        }

        const data = await response.json();

        // Return data only if the ISO code is 'US'
        return data?.countryRegion?.isoCode === 'US' ? data : null;
    } catch (error) {
        console.error('Error retrieving location data:', error);
        return null;
    }
};

export default readUserLocation;