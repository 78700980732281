import { GET_GROUP_API } from '../config';

const fetchGroups = async (countryCode) => {
    // Validate required parameter
    if (!countryCode) {
        throw new Error('Country code is required.');
    }

    try {
        // Create the URL and append the query parameter using URLSearchParams
        const url = new URL(GET_GROUP_API);
        url.searchParams.append('countryCode', countryCode);

        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Sort the data by the 'name' property if data is an Array
        if (Array.isArray(data)) {
            data.sort((a, b) => a.name.localeCompare(b.name));
        } else {
            console.warn("Expected an array, but received:", data);
        }

        return data;
    } catch (error) {
        console.error('Failed to fetch groups:', error);
        throw error;
    }
};

export default fetchGroups;
